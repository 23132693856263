$(document).ready(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    getRecaptcha();
    sendFormAjaxCaptha();

    function getRecaptcha(){
        let siteKey = $('meta[name="recaptcha-site-key"]').attr('content');

        grecaptcha.ready(function() {
            grecaptcha.execute(siteKey, {action: 'submit'}).then(function(token) {
                let grecaptcha_value = $('input[name="g-recaptcha-response"]').val(token);
            });
        });
    }

    function sendFormAjaxCaptha() {
        // Send Form Data Text and File
        let lastClick = 0;
        $('#send-form-ajax-captcha').on('click', function(e) {
            e.preventDefault();

                getRecaptcha();

            const thisClick = Date.now();
            var obj = $(this);
            obj.addClass('disabled');

            var submitForm = document.getElementById('submit-ajax-form');
            var data = new FormData(submitForm);

            var method = submitForm.getAttribute('method');
            var url = submitForm.getAttribute('action');
            if (thisClick - lastClick > 3000) {
                $.ajax({
                    type: method,
                    enctype: 'multipart/form-data',
                    url: url,
                    data: data,
                    processData: false,
                    contentType: false,
                    dataType: 'json',
                    cache: false,
                    beforeSend: function (e) {
                        Swal.fire({
                            icon: "info",
                            title: "Harap menunggu",
                            html: "Permintaan Anda sedang diproses.",
                            showConfirmButton: false,
                            timer: false
                        });
                    },

                }).done(function (response) {
                    obj.removeClass('disabled');
                    Swal.close();
                    resetFeedback();
                    //icon, title, text, confirmButton, cancelCutton, timer

                    if (response.forceReload) {

                        _alert(response.status, response.title, response.message, response.confirm, response.cancel, response.timer, response.redirectUrl);

                    } else {

                        _alertAjax(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);
                        
                        clearFormRegister();

                        if(response.auth_register){
                            authUserRegisterTable(response.datas);
                        }
                    }

                }).fail(function (xhr, status, error) {
                    obj.removeClass('disabled');
                    Swal.close();
                    resetFeedback();
                    getFeedback(xhr.responseJSON.errors);

                    if(typeof (xhr.responseJSON.errors['g-recaptcha-response']) !== 'undefined'){
                        _alert('error', xhr.responseJSON.errors['g-recaptcha-response'][0], 'Halaman akan di refresh untuk melanjutkan.', true, false, false, '');
                    }
                });
            } else {

                Swal.close();
                button.removeClass('disabled');

                _alertAjax('error', 'Harap menunggu', 'Tombol terkunci selama 3 detik.', true, false, false);

            }

            lastClick = thisClick;

        });
    }

    function authUserRegisterTable(datas){
        let auth_user_register = "";
        
        $.each(datas, function(index, data){
            auth_user_register += `
                <tr class="tb-tnx-item">
                    <td class="tb-tnx-info">
                        <span class="d-none d-sm-inline-block">
                            <span class="title">${data.display_created_at_date}</span>
                            <span class="sub-text">${data.display_created_at_time}</span>
                        </span>

                    </td>
                    <td class="tb-tnx-info">
                        <div class="tb-tnx-desc">
                            <span class="title">${data.name}</span>
                            <span class="sub-text">${data.display_age}</span>
                            <span class="sub-text">${data.phone}</span>
                        </div>
                        <div class="tb-tnx-desc">
                            <span class="title">${data.organization}</span>
                            <span class="sub-text">${data.email}</span>
                        </div>
                        <div class="tb-tnx-desc d-sm-none">
                            <span class="sub-text">${data.display_created_at}</span>
                        </div>
                    </td>
                    <td class="tb-tnx-amount">
                        <span class="tb-tnx-total d-md-block">
                            <span class="title">${data.event.name}</span>
                            <span class="sub-text">{${data.registration_type.name}</span>
                        </span>
                    </td>
                </tr>
            `;
        });

        $('.refresh-auth-event-users').html(auth_user_register);
    }

    function clearFormRegister()
    {
        $('#form-input-name').val('');
        $('#form-input-age').val('');
        $('#form-input-email').val('');
        $('#form-input-phone').val('');
    }

    getFeedback = (errors) => {
        $.each(errors, function (error, e) {
            $("input[name='" + error + "']").addClass('is-invalid');
            var e_msg = "";
            if (e.length > 1) {
                $.each(e, function (error, msg) {
                    e_msg += "<i class='fas fa-angle-right'></i> " + msg + "<br>";
                });
            } else {
                e_msg = e;
            }

            $('.error-' + error).html(e_msg);
        });
    }

    resetFeedback = () => {
        $(".form-control").removeClass('is-invalid');
        $(".form-note").html('');
    }

});